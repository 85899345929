@import '~@arcault-ui/shared-components/dist/styles.css';

.documents-container {
  display: flex;
  flex-direction: column;
  height: 100%; /* Fill all available vertical space */
  padding: var(--spacing-4);
}

.documents-container > .card-body {
    padding-bottom: 0px;
    display: flex;
}

.documents-container .doc-icon {
  position: relative; 
  height: 60px;
  text-align: center;
}

.documents-container .doc-icon .icon {
  font-size: 64px;
  color: var(--color-secondary);
}

.documents-container .doc-icon .caption {
  position: absolute;
  top: 36px;
  font-size: 10px;
  text-align: center;
  width: 100%;
}

.documents-container .card-footer {
  padding: var(--spacing-2) 0px;
}

.documents-container .doc-card-actions {
  gap: 12px;
}

.documents-container .doc-card-actions .icon {
  font-size: 18px;
  color: var(--color-primary);
}

.documents-container .doc-card {
  padding: var(--spacing-1) 0px;
}

.documents-container .doc-card .card-body {
  padding: 0
}

.documents-container .doc-card .card-header {
  font-size: var(--font-size-caption);
  line-height: var(--line-height-caption);
  font-weight: var(--font-weight-caption);
  height: var(--spacing-8);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--spacing-2);
  padding: 0;
  text-align: center;
}

.documents-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-4);
  flex: 1;
}

.documents-container .document-item, .documents-container .doc-card {
  background-color: white;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-neutral-5);
    border-radius: var(--spacing-2);
    min-height: 152px; /* Ensure all team members have the same height */
    background-color: white;
}

.document-item .document-item-content {
  padding: var(--spacing-3);
  display: flex;
  text-align: center;
}

.document-item-content .caption {
  height: 36px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: wrap;
}

.document-item .document-item-content .icon {
  color: var(--color-secondary);
}

.card.document-item {
  padding: 0px;
  height: 100px;
}

.document-item .card-body, .document-item .card-header {
  padding: 0px;
}

.document-item .card-body, .document-item .card-body .dropzone {
  justify-content: center;
  display: flex;
}

.document-item.outline, .document-item.ghost {
  border: 1px dashed var(--color-navy-10);
  box-shadow: none;
}