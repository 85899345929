/* @import '../../App.css';*/
@import '/node_modules/@arcault-ui/shared-components/dist/styles.css';


.contact-card .actions {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 8px;
}


.contact-card .card-body {
    display: flex;
    justify-content: space-between;
}

.contact-card .contact-name {
    font-size: var(--font-size-h3);
    font-weight: var(--font-weight-h3);
    line-height: 32px;
}

.contact-card .arcault-avatar {
    height: 64px;
    width: 64px;
}

.contact-card button {
    margin-right: 8px;
}

.contact-card button .icon {
    font-size: 18px;
    font-weight: 100;
}

.arcault-table-body .arcault-avatar {
    width: 24px;
    height: 24px;
}

.arcault-table-body .chakra-avatar__initials {
    font-size: 12px;
    color: white;
}
