@import '~@arcault-ui/shared-components/dist/styles.css';

.progress-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}

.progress-stage {
    padding-left: var(--spacing-1)
}

.progress-amt {
    padding-left: var(--spacing-8);
    font-size: var(--spacing-16);
    color: var(--color-primary);
    margin-bottom: var(--spacing-2);
    line-height: 64px;
}

.progress-label {
    padding-left: var(--spacing-8);
    font-size: var(--font-size-subheading);
    font-weight: var(--font-weight-subheading);
    line-height: 0px;
    color: var(--color-accent);
    margin-bottom: var(--spacing-2);
}