.previous-step {
    padding-top: var(--spacing-3);
    border-top: 1px solid var(--color-neutral-5);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.card-move-down {
    animation: moveDown .5s;
}

.dashboard-next-step-inner .skip-line {
  flex: 0 0 16px;
  display: flex;
}

@keyframes moveInto {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    50% {
      transform: translateY(-20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px); /* Return to starting position */
      opacity: 1;
    }
  }

@keyframes moveDown {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(100px);
    opacity: 0;
  }
}

.previous-step-inner {
    animation: moveInto .5s;
}

.previous-step-info {
    display: flex;
}

.previous-header {
    font-size: var(--font-size-caption);
    font-weight: var(--font-weight-caption);
    color: var(--color-accent);
    margin-right: var(--spacing-2);
}

.previous-step .icon {
    color: var(--color-accent);
}

.next-steps-input-group {
    align-items: flex-end;
    gap: 0px !important;
    padding-bottom: var(--spacing-2);
    flex: 0 !important;
}

.next-steps-input-group-active .arcault-input {
    border-right: 0px !important;
    border-radius: 8px 0px 0px 8px !important;
}

.next-steps-input-group .arcault-button--primary {
    margin-bottom: 16px;
    height: 42px;
}

.next-steps-input-group-active .arcault-button--primary {
    border-radius: 0px 8px 8px 0px;
    border: 1px solid transparent;
}