
.access-token {
  display: block;
  width: 100%; /* Adjust the width as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sitcom-table .card-view {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.sitcom-table .card-view .card-body {
  padding: 0px;
}

.card-view .card.selection-card {
  flex: 0 0  200px;
}

.card.selection-card .body {
  line-height: 16px;
}

.card.selection-card img {
  opacity: .8
}

.card.selection-card .veil  {
  display: none;
  background-color: white;
  opacity: .9;
  position: absolute;
  top: 80px;
  width: 166px;
  height: 266px;
  z-index: 100;
}

.selected-card {
  margin-top: 36px !important;
  width: 800px;
  margin: 0 auto;
}
.selected-card-left {
  flex: 0 0 250px;
  padding-right: 16px;
  border-right: 1px solid var(--color-neutral-20);
  height: 475px;
}

.selected-card-right {
  height: 475px;
}

.agent-thread {
  overflow-y: scroll;
}

.card.selection-card .persona {
  display: none;
  position: absolute;
  top: 72px;
  padding: 12px;
  width: 166px;
  height: 256px;
  font-size: 12px;
  text-align: left;
  color: var(--color-primary);
  z-index: 101;
  
}

.selection-card.card-can-hover:hover {
  cursor: pointer;
  background-color: white;
  box-shadow: none;
}


.card.selection-card:hover .veil, .card.selection-card:hover .persona {
  display: block;
}

.card.selection-card .icon {
  font-size: 64px;

  text-align: center;
  position: absolute;
  bottom: 0px;
  left: 52px;
  opacity: 1

}

.selection-card-custom-content {
  gap: 4px;
}

.card.selection-card-custom .body-text-large {
  padding-bottom: var(--spacing-4);
}

.card.selection-card-custom .input-label {
  font-size: var(--font-size-caption);
  display: none;
}

.card.selection-card-custom .arcault-textarea {
  height: 152px;
}

.card.selection-card-custom .form-input {
  padding-bottom: var(--spacing-2);
}

.card.selection-card-custom .form-status {
  display: none;
}

.card.selection-card-custom .arcault-input {
  font-size: var(--font-size-caption);
  line-height: var(--line-height-caption);
}
