.header {
    width: 100%;
    height: var(--spacing-14);
    padding: var(--spacing-2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--color-neutral-1);
}

