@import '../../../App.css';

.card-dashboard > .card-body {
    padding: 0px var(--spacing-4);
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
}

.card-dashboard > .card-header {
    display: none;
}

.your-vault {
    margin: var(--spacing-8) 0px 0px;
    padding: var(--spacing-2) 0px 0px;
    border-top: 1px solid var(--color-neutral-10);
}

.help-link {
    color: var(--color-secondary);
    font-weight: bold;
}

.your-vault-card {
    border: none;
    text-align: center;
    flex: 1;
    box-shadow: none;
    text-align: left;
}
      
.your-vault-card > .card-body {
    display: flex;
    flex: 1 1 250px;
    overflow-y: scroll;
}

.your-vault-card .card-footer {
    border-top: none;
}

.dashboard-planning-content {
    height: 300px;
    height: 100%;
    flex: 0;
}

.help-section {
    margin: 36px auto;
    padding: 0px 32px 16px;
}



.dashboard-planning-content .arcault-button--ghost {
    font-size: 14px;
    padding: 0px 10px;
}

.modal {
    /*width: 1200px !important;
    max-width: none !important;*/
}

.modal .card-header {
    padding: 0;
}

.modal .arcault-avatar {
    height: 96px;
    width: 96px;
    margin: auto;
    margin-bottom: 8px;
}

.compare-section {
    padding: var(--spacing-4) 0px;
    border-bottom: 1px solid var(--color-neutral-5);
}

/* Next Step */

.dashboard-next-step {
    padding: 0px 0px var(--spacing-4);
    margin: auto;
    width: 100%;
}

.dashboard-next-step:not(.card-dashbord) .card-header {
    background-color: var(--color-mint-30);
    color: white;
    font-weight: var(--font-weight-h2);
    border-radius: var(--spacing-2) var(--spacing-2) 0px 0px;
    padding-top: var(--spacing-3);
    padding-bottom: var(--spacing-4);
}

.dashboard-next-step.priority-card-dashbord .card-header {
    background-color: var(--color-mint-30);
    color: white;
    font-weight: var(--font-weight-h2);
    border-radius: var(--spacing-2) var(--spacing-2) 0px 0px;
    padding-top: var(--spacing-3);
    padding-bottom: var(--spacing-4);
}

.dashboard-next-step.dashboard-card .card-header {
    background-color: var(--color-navy-1);
    color: var(--color-primary);
    font-weight: var(--font-weight-h2);
    border-radius: var(--spacing-2) var(--spacing-2) 0px 0px;
    padding-top: var(--spacing-3);
    padding-bottom: var(--spacing-4);
}

.dashboard-next-step .card-body {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    /* justify-content: start;*/
    padding-bottom: 0px;
    /* flex: 0 0 186px;*/
    /* overflow: hidden; */
}

.dashboard-next-step .card-footer {
    padding: var(--spacing-4) var(--spacing-4) 0px;
}

.dashboard-next-step .dashboard-next-step-inner {
    gap: 16px;
    flex: 1;
}



.dashboard-find-attorney.dashboard-next-step-inner {
    gap: 32px;
}

.dashboard-find-attorney button {
    width: 200px;
}



.search-contacts .form-input {
    padding-bottom: var(--spacing-2)
}

.add-team-member-list .arcault-avatar {
    height: 48px;
    width: 48px;
}

.add-team-member-list-contact, .add-team-member-list .arcault-stack {
    gap: 0px;
}

.find-attorney-button {
    /* border: 4px solid var(--color-primary); */
    border-radius: var(--spacing-4);
    padding: 0;
    flex: 0;
}

.card-can-hover.find-attorney-button:hover {
    background-color: var(--color-accent);
    color: white;
}

.card-can-hover.find-attorney-button:hover .text {
    color: white;
}

.card-can-hover.find-attorney-button:hover .arcault-button {
    background-color: white;
    color: var(--color-primary);
}

.card-can-hover.find-attorney-button:hover .arcault-button:hover {
    background-color: var(--color-accent-dark);
    color: white;
}

.find-attorney-button .card-body {
    padding: 0px;
}

.find-attorney-button .card-header {
    display: none;
}

.find-attorney-button-text-box {
    padding: var(--spacing-1)
}

.find-attorney-button img{
    height: 130px;
    border-radius: 0px var(--spacing-3);
}

.dashboard-find-attorney-image {
    height: 130px;
}

.selected-stack {
    margin: var(--spacing-4) 0px;
    border-top: 1px solid var(--color-neutral-5);
    padding: var(--spacing-4) 0px;
}

.selected-stack-list-item {
    height: 60px;
}

textarea.chakra-textarea.css-0 {
    border: 1px solid var(--color-neutral-5);
    border-radius: 8px;
    width: 100%;
    padding: var(--spacing-2);
    font-family: var(--font-family);
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: radial-gradient(circle, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.2));*/
    /* background: radial-gradient(circle, rgba(40, 167, 69, 0.2), white);*/
    background: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .overlay .chakra-skeleton__group {
    width: 100%;
    height: 30%;
  }

  .overlay .Check-icon {
    font-size: 80px;
    color: var(--color-success);
  }

  .step-container {
    position: relative;
    display: flex;
    flex: 1;
  }

  .overview-inner-1 #step-0 .dashboard-next-step-inner {
    justify-content: center;
}

  #step-0 .dashboard-next-step-inner .arcault-button--secondary {
    background-color: #D19F36;
    color: white;
    font-weight: bold;
  }

.direction-text {
    top: 64px;
    position: absolute;
    right: 25px;
    color: var(--color-neutral-20);
}

.gold-text {
    color: var(--color-secondary)
}

.main-dashboard-card {
    padding-bottom: 32px;
    width: 550px;
    margin: auto; 
}

.dashboard-data {
    padding-bottom: 32px;
    margin: auto; 
}

.card-light-border {
    border: 1px solid var(--color-neutral-5);
    background-color: var(--color-neutral-5);
}

.dashboard-planning-content-mobile {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-4);
  }
  
  .card-dashboard {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
  }

  .mobile-team-viewer, .mobile-doc-viewer { display: none }
  
  @media (max-width: 600px) {
    .main-content { width: 100%; padding: var(--spacing-2) !important }

    .team-viewer-inner {
        display: none;
    }  

    .card-dashboard {
      width: 100%;
      padding: var(--spacing-4);
    }

    .card-dashboard .card-header {
        display: none;
    }

    .card-dashboard > .card-body {
        padding: 0px;
  
    }

    .dashboard-progress .card-body {
        padding: 0px;
    }
    .dashboard-progress .progress-container {
        flex-direction: row;
    }

    .dashboard-progress .progress-stage {
        width: 100%;
        display:none;
    }

    .dashboard-progress .progress-info {
        flex: 0 0 48px;
        padding-right: 12px;
    }
    .dashboard-progress .progress-info .progress-label {
        padding-left: 0px;
        margin-bottom: 0px;
        line-height: 8px;
    }

    .dashboard-progress .progress-info .progress-amt {
        padding-left: 0px;
        font-size: var(--spacing-12);
        margin-bottom: 0px;
    }

    .dashboard-progress .progress-bar {
        flex: 3;
        background-color: var(--color-neutral-5);
    }

    .mobile-doc-viewer { flex: 1; display: 'flex' }

    .your-vault .text {
        font-weight: var(--font-weight-h3);
        font-size: var(--font-size-h3);
    }

    .your-vault-content .arcault-h-stack { flex-direction: column; gap: 8px; flex: 0 0; }

    .team-container, .documents-container {
        padding: 0px;
    }

    .estate-value-card .card-body, .documents-container .card-body, .team-container .card-body {
        padding: 0px 0px 0px 12px;
    }
    .estate-value-card .card-header, .team-container .card-header, .documents-container .card-header {
        display: none;
    }

    .estate-value-card .card-footer, .team-container .card-footer, .documents-container .card-footer {
        display: none;
    }

    .estate-value-card.your-vault-card, .team-container.your-vault-card, .documents-container.your-vault-card {
        display: flex;
        flex-direction: row;
        padding: 0px;
    }

    .estate-value-card .description {
        display: none;
    }

    .estate-value-card .estate-value-inner, .team-container .mobile-team-viewer, .documents-container .mobile-doc-viewer {
        flex-direction: row;
        display: flex;
        gap: 16px;
        justify-content: space-between;
        width: 100%;
        flex: 1;
    }
    .estate-value-card .estate-value-inner .text {        
        font-size: var(--font-size-h4);
        line-height: var(--line-height-h4);
        font-weight: var(--font-weight-h4);
    }

    .estate-value-card .estate-value-inner .text:nth-child(2), .mobile-team-viewer .text:nth-child(2), .mobile-doc-viewer .text:nth-child(2) {
        color: var(--color-accent);
    }

    .documents-list { display: none }

    .card-dashboard .proposal-inbox {
        display: none;
    }
    
    .card-dashboard .your-vault-content {
        /* display: none;*/ 
    }

    .dashboard-planning-content {
        flex-direction: column;
    }

    .dashboard-next-step {
        width: auto;
    }
  }


  /* Inbox Dashboard */

  .inbox-dashboard-right {
    flex: .5;
  }

  .inbox-dashboard-right .estate-value-card {
    flex: .5

  }