/* Drawer.css */
@import '~@arcault-ui/shared-components/dist/styles.css';

.drawer {
  position: fixed;
  top: 0;
  right: 0;
  width: 600px;
  padding: var(--spacing-4);
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  border-top: var(--spacing-4) solid var(--color-secondary);
  z-index: 1000;
}

.drawer-header {
  padding-bottom: var(--spacing-4);
}

.drawer.open {
  transform: translateX(0);
}