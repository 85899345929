@import '~@arcault-ui/shared-components/dist/styles.css';



.team-container {
    border-right: 1px solid var(--color-neutral-1);
    border-left: 1px solid var(--color-neutral-1);
    border-radius: 0px;

    box-shadow: none;

    display: flex;
    flex-direction: column;
    height: 100%; /* Fill all available vertical space */
    padding: var(--spacing-4);
  }

.team-container > .card-body {
    padding-bottom: 0px;
    display: flex;
}
  .team-members {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-4);
  }
  
  .card.team-member {
    padding: 0px;
    gap: 0px;
    height: 100px;
  }

  .team-member {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-neutral-5);
    border-radius: var(--spacing-2);
    min-height: 152px; /* Ensure all team members have the same height */
  }

  .team-member .arcault-stack {
    text-align: center;
    align-items: center;
    gap: 0px;
  }

  .team-member .arcault-avatar {
    height: var(--spacing-16);
    width: var(--spacing-16);
    background-color: var(--color-secondary);
    margin-top: var(--spacing-4);
}
  .team-member.outline .arcault-avatar {
    background-color: var(--color-neutral-1);
  }

.team-member .arcault-avatar .chakra-avatar__initials {
    font-size: var(--font-size-h3);
    font-weight: bold;
    color: white;
}

  .team-member.outline, .team-member.ghost {
    border: 1px dashed var(--color-navy-10);
    box-shadow: none;
  }

  .team-member .role-label {
    padding: var(--spacing-2);
    height: var(--spacing-8);
    text-align: center;
  }
  
  .team-member.ghost .arcault-avatar {
    background-color: var(--color-neutral-10);
  }


.team-member .card-body, .team-member .card-header {
  padding: 0px;
}

.team-member .card-header {
  font-size: var(--font-size-caption);
  line-height: var(--line-height-caption);
  font-weight: var(--font-weight-caption);
  height: var(--spacing-8);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--spacing-2);
  padding: 0;
  text-align: center;
}

.team-member .card-body, .team-member .card-body .dropzone {
  justify-content: center;
  display: flex;
}