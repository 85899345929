@import '../../App.css';

.dashboard-left {
    padding-right: 250px;
}

.dashboard-next-step-container .dashboard-next-step-inner {
    gap: 0px;
    /* height: 182px;*/
}

.basic-info-modal {
    height: 454px;
}

.basic-info-title {
    gap: 8px;
}

.dashboard-next-step-container {
    padding-top: var(--spacing-6);
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding-bottom: 0px;
    /*height: 186px;*/
    overflow: hidden;
    width: 500px;
    margin: auto;
    overflow: hidden; 
    position: relative
  }

  .family-photo {
    height: 500px;
    position: absolute;
    right: -232px;
  }


.choices-container {
    text-align: center;
    display: flex;
    justify-content: center;
    padding-top: 24px;
    width:100%;
    gap: 16px;
  
  }
  
  .journey-choice.journey-choice-recommended {
    /* border: 4px solid var(--color-accent);*/
  }
  
  .journey-choice {
    /* border: 2px solid var(--color-primary);*/ 
    height: 284px;
    flex: 0 0 172px;
    
  }

  .journey-choice:hover {
    background-color: var(--color-navy-10);
    cursor: pointer;
  }

  .journey-choice-inactive:hover {
    background-color: var(--color-neutral-10)
  }

  .journey-choice.journey-choice-recommended:hover {
    background-color: var(--color-mint-10);
  }

  .journey-choice-inactive .card-footer .text {
    padding: 0;
    height: 36px;
  }
  
  .journey-choice .card-header {
    flex: 1 1 100px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  
  .journey-choice-icon {
    display: flex;
    height: 100px;
    justify-content: center;
    align-items: center;
  }
  
  .journey-choice-icon img {
    height: 100px;
  }
  

.choices-scroll-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding: 1rem 0;
}

.choices-row {
  display: flex;
  gap: 1rem;
  min-width: max-content;
}

.journey-scroll-wrapper {
  position: relative;
}

.choices-scroll-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding: 1rem 0;
}

.choices-row {
  display: flex;
  gap: 1rem;
  min-width: max-content;
}

.scroll-arrow {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  z-index: 10;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  font-size: 2rem;
  padding: 0 0.5rem;
  cursor: pointer;
  transition: background 0.2s;
  box-shadow: 0 0 6px rgba(0,0,0,0.1);
}

.scroll-arrow:hover {
  background: rgba(255, 255, 255, 1);
}

.scroll-arrow.left {
  left: -10px;
}

.scroll-arrow.right {
  right: -10px;
}
