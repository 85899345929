@import '~@arcault-ui/shared-components/dist/styles.css';


.card.inbox-card {
    max-width: none;
}

.proposal-inbox {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.proposal-inbox .card-view {
    grid-template-columns: none;
}

.proposal-inbox .view-switcher {
    display: none;
}

.proposal-inbox .table-controls {
    flex: 0 0 40px;
}

.proposal-inbox .table-controls .search-bar {
    padding-top: 0px;
}

.proposal-inbox .inbox-full-card .card-header {
    display:none;
}

.proposal-card-info-stack { gap: 0;}

.modal.compare-proposals {
    width: 1200px !important;
    max-width: 1200px;
}

.proposal-inbox .list-item {
    padding: var(--spacing-2) var(--spacing-3);
}

.proposal-inbox .arcault-avatar {
    height: var(--spacing-14);
    width: var(--spacing-14);
}

.proposal-inbox .offer-card {
    max-width: 750px;
}

.dashboard-planning-content .proposal-inbox {
    height: 330px;
}
.dashboard-planning-content .proposal-inbox-incomplete {
    background-color: var(--color-neutral-5);
}

.dashboard-planning-content .proposal-inbox .list-item {
    background-color: white;
}
.dashboard-planning-content .proposal-inbox .card-body {
    display: flex;
    flex-direction: column;
}

.dashboard-planning-content .proposal-inbox .card-header:first-child  {
    padding: 0px;
    display: flex;
    justify-content: space-between;
}

.dashboard-planning-content .proposal-inbox .card-body {
    padding-top: 0px;
    padding-bottom: 0px;
}

.dashboard-planning-content .proposal-inbox .card-body .card-header {
    padding: 0px 0px var(--spacing-2);
}