.rfp-edit-modal .caption {
    padding-bottom: var(--spacing-4);
}

.rfp-edit-modal .rfp-modal-summary textarea {
    height: 160px;
}

.rfp-edit-modal .rfp-modal-notes textarea {
    height: 116px;
}

.rfp-edit-modal {width: 800px; min-width: 800px;}