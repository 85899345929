@import '../../App.css';

.json-key {
    width: 150px;
}

.json-value {
    width: 250px;
}

.json-key  .arcault-input, .json-value .arcault-input {
    background-color: white !important;
    border: 1px solid var(--color-neutral-5) !important;
}

.json-key .arcault-input {
    text-align: right;
    font-weight: bold;
}

.expand-button {
    width: 64px;
}

.expand-button, .array-item {
    height: 42px;
}

.array-item {
    display: flex;
    align-items: center;
    width: 64px;
}

.array-label {
    color: var(--color-accent);
    height: 42px;
    display: flex;
    align-items: center;
}

.drawer-close {
    position: absolute;
    top: 8;
    right: 0;
    padding: 8px;
    cursor: pointer;
}

.filter-body {
    padding-top: 32px;
    padding-left: 16px;
}

.drawer-close {
    position: absolute !important;
    right: 0;
    top: 8px;
}

.hide-controls, .show-only-controls {
    color: var(--color-accent);
}

.hide-controls {
    padding-left: 12px;
}

.shown-key {
    font-weight: bold;
}

.hidden-key, .shown-key {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.top-filter {
    border-bottom: 1px solid #ddd;
    margin-bottom: 16px;
}

.json-editor .card-header {
    padding:0;
}

.json-editor .card-body {
    padding-top: 0;
}

.expand-collapse-toggle {
    width: 200px;
}

/*.search-bar {
    width: 300px !important;
    padding-top:0px;
}*/