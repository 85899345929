.table-row-liabilities .table-column-estimatedValue {
    color: var(--color-error);
}

.table-row-liabilities .table-column-estimatedValue::before {
    content: '-$';
}

.table-row-assets .table-column-estimatedValue::before {
    content: ' $';
}

.icon-has-secret .arcault-icon-primary {
    color: var(--color-success) !important;
}

.table-row-total {
    border-top: 1px solid var(--color-neutral-10);
    /* background-color: var(--color-secondary-light);*/
    color: var(--color-accent);
}

.table-column-estimatedValue {
    width: 180px;
}

td.table-column-description {
    max-width: 300px;
}

/*.table-column-estimatedValue {
    width: 100px;
    text-align: right;
}*/