@import '~@arcault-ui/shared-components/dist/styles.css';

:root {
  /* --elevation-1: 5px 5px 10px #bebebe, -5px -5px 10px #ffffff;*/
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.login-area {
  width: 450px;
  margin: 50px auto;
}

.login-area .card-header {
  display: none;
}

.chakra-alert {
  background-color: blue;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}

.instructions > svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.line {
    display: inline-block;
}
.logo{
  height: 10px;
  min-height: 10px;
}
/*.hero{*/
/*      background-image: url("assets/img/hero-bg2.png");*/
/*      background-size: cover;*/
/*      background-position: 200% 50%;*/
/*    }*/
    
.no-border {
    border: none;
}
.smImg{
  width: 100px;
}
.text-justify{
  text-align: justify;
  text-justify: inter-word;
}
.default-color-container{
  background-color: #dde9e9;
}

/* 
* typograph
*
*/

h4 {
  font-size: 20px;
  line-height: 28px;
}

h2 {
  font-size: var(--font-size-h2);
  line-height: var(--line-height-h2);
  font-weight: var(--font-weight-h2);
}

caption {
    font-size: var(--font-size-caption);
    font-weight: var(--font-weight-caption);
    line-height: var(--line-height-caption);
}

/*.header .hamburger-menu {
  display: none;
} */

 
.profile-icon {
  background-color: white;
}

.header .profile-icon, .header .profile-icon:hover {
  background-color: white;
}


.header .profile-icon .arcault-avatar {
  height: var(--spacing-10);
  width: var(--spacing-10);
}

.table-controls {
  padding-top: 16px;
}

.table-controls .search-bar {
  padding: 0;
}


.header .chakra-avatar__initials {
  font-size: var(--spacing-4);
  color: white;
}

/* New Nav */

.header .hamburger-menu {
  display: flex;
}

.navigation.desktop-nav {
  display: none;
}

.nav-stacks {
  gap: 2px;
}

.top-nav-stacks {
  border-bottom: 1px solid var(--color-neutral-5);
  padding-bottom: var(--spacing-1);
  margin-bottom: var(--spacing-3);
}

.header .arcault-logo {
  height: 32px;
}

.header .arcault-logo img {
  height: 24px;
  padding-right: var(--spacing-1);
}

.header .arcault-logo {
  font-size: 32px;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: var(--color-primary);
  
}


@media (max-width: 600px) {
  .header {
    height: var(--spacing-12);
    padding: var(--spacing-2);
  }
  
  .header .arcault-logo, .header .arcault-logo img {
    height: 32px;
  }
  
  .header .profile-icon {
    background-color: white;
  }
  
  .header .profile-icon .chakra-avatar {
    height: 32px;
    width: 32px;
  }
}

.background-base {
  background-color: var(--color-navy-1);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}

.background-layer {
  /*background-image: url('./background.svg'); */
  /* Set the background image */
  background: url('big_background.png');

  /* Make the image cover the full screen and fix its position */
  /*background-attachment: fixed;
  background-position: -250px -100px;
  background-size: 600px 800px;
  background-repeat: no-repeat;'*/
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  opacity: 0.8;
  top: 0;
  width: 100%;
  height: 100%;

  z-index: -1;

}

.lead-meta-data {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: var(--spacing-8);
}

.lead-meta-data .arcault-h-stack {
  flex: 0 1 50%;
}

.lead-meta-data .arcault-h-stack .text {
  flex: 2;
}

.lead-meta-data .arcault-h-stack .text:first-child {
  text-align: right;
  flex: 3;
}

.main-content {
    padding: 16px;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--spacing-12)); /* Adjust height to be full height after the header */
}

.navigation {
    padding: var(--spacing-2);
    width: 291px;

      position: fixed;
      background-color: white;
      z-index: 1;
      box-shadow: var(--elevation-right-1);
      top: var(--spacing-12);
      left: 0px;
}

.navigation .icon {
    padding-right: var(--spacing-2);
}

.navigation .nav-item {
    border-radius: var(--spacing-2);
    height: var(--spacing-14);
    display: flex;
    align-items: center;
    padding: var(--spacing-4);
    margin-bottom: var(--spacing-2);
    font-size: var(--font-size-subheading);
    line-height: var(--line-height-subheading);
    font-weight: var(--font-weight-subheading);
}

.navigation .nav-item-inner {
  align-items: center;
}



/* Tablet Size: Adjust for medium screens */
@media (max-width: 1024px) {
  .navigation {
      width: 98px;
  }
  
  .navigation .icon {
    padding-right: 0;
    padding-bottom: var(--spacing-1);
  }
  
  .navigation .nav-item {
      padding: var(--spacing-2);
      font-size: var(--font-size-caption);
      line-height: 14px;
      height: 72px;
      flex-direction: column;
      text-align: center;
  }
  
  .navigation .nav-item-inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
  }
}

@media (max-width: 600px) {
  .navigation {
    width: 192px;
    position: fixed;
    background-color: white;
    z-index: 1;
    box-shadow: var(--elevation-right-1);
    top: var(--spacing-12);
    left: 0px;
  }
  
  .navigation .icon {
    padding-right: var(--spacing-1);
    padding-bottom: 0;
    font-size: var(--font-size-caption);
  }
  
  .navigation .nav-item {
    border-radius: var(--spacing-2);
    height: var(--spacing-8);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: var(--spacing-2);
    margin-bottom: var(--spacing-2);
    text-align: left;
  }
  
  .navigation .nav-item-inner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
}

.navigation .active, .navigation .active:hover {
   background-color: var(--color-primary);
}

.navigation .active div, .navigation .active:hover div {
   color: var(--color-secondary);
}

.navigation a:hover {
   background-color: var(--color-neutral-light);
   box-shadow: var(--elevation-1);
}

.navigation a:hover div {
    color: var(--color-accent);
}

.navigation a:active {
   background-color:var(--color-neutral-30);
   box-shadow: var(--elevation-0);
}

.neu-card {
  border-radius: 8px;
  background: white;
  box-shadow:  7px 7px 14px #bebebe, -7px -7px 14px #ffffff
}

.neu-card-low {
  border-radius: 8px;
  background: white;
  box-shadow:  5px 5px 10px #bebebe, -5px -5px 10px #ffffff
}


.card-dashboard {
  display: flex;
  flex-direction: column;
  
  max-width: 1300px;
  margin: auto;
  height: 100%;
}

.card-mint-highlight {
  border: 1px solid var(--color-mint-10);
}

.card-no-shadow {
  box-shadow: none;
}

.card-full-height {
  height: 100%;
}

/* Overview panel */
.json-editor {
  flex: 0 0 800px;
}
.card.overview-inner {
  background-color: white;
  margin: auto;
  max-width: 1200px;
  padding: var(--spacing-8);
  flex: 0 0 600px;
  height: 660px;
}

.overview-inner-loading {
  width: 100%;
}

.overview-inner-loading .overlay-inner {
  align-items: center;
}

.overview-inner-loading .overlay-inner .text {
  color: var(--color-primary);
}

.card.overview-inner-1 {
  flex: 0 0 1100px;
  height: 700px;
}

.card.overview-inner-1 .dashboard-data > .text {
  width: 550px;
  margin: auto;
}

.card.overview-inner-1 .priority-card-dashboard, .card.overview-inner-1 .dashboard-card {
  width: 550px;
  margin: auto;
}

.card.overview-inner .text {
  padding-bottom: 16px;
}

.card.overview-inner > .card-body {
  padding: 0px var(--spacing-4);
} 
.card.overview-inner > .card-header { display: none; }

.overview-content {
  padding-top: var(--spacing-4);
  display: flex;
  flex-wrap: wrap;
  gap: 48px; /* Adds a 16px gap between the cards */
}

.overview-content > .card {
  flex: 1 1 calc((100% - 48px) / 2);/*  3 cards per row with 16px gap, 32px total for two gaps */
  max-width: calc((100% - 48px) / 2); /*Prevents cards from growing too large */
  box-sizing: border-box;
  height: 320px;
}

.overview-card {
  height: 320px;
}

@media (max-width: 600px) {
  .chakra-card {
    flex: 1 1 100%; /* Cards take full width on screens smaller than 600px */
  }
}

.dashboard {
  flex: 1;
  width: 1100px;
}

.dot {
  position: relative;
}

.dot::after {
  content: '';
  position: absolute;
  top: -5px;
  right: -5px;
  width: 10px;
  height: 10px;
  background-color: var(--color-accent);
  border-radius: 50%;
}

.button-group {
  flex: 0 0 48px;
}

/* to Move to shared-components */

.section {
  padding-bottom: var(--spacing-8);
}

.form-menu {
  margin-bottom: var(--spacing-3);
  width: 100%;
}

.chakra-button {
  padding: var(--spacing-2) var(--spacing-4);
}

.dashboard-planning-image .MuiSvgIcon-root {
  font-size: 64px;
  color: var(--color-primary);
}

.dashboard-planning-choice .card-header {
  font-weight: bold;
    color: var(--color-primary);
    font-size: 22px;
}

/* to Move */

.arcault-textarea {
  padding-right: 120px;
}

.form-input .arcault-textarea.input-filled {
  border: none;
  background-color: var(--color-mint-1);
}

.arcault-input-group .right-help {
  position: absolute;
  right: 12px;
  top: 8px;
  color: var(--color-primary-light);
}

.arcault-button.arcault-button--link {
  padding: 0px 0px var(--spacing-1) var(--spacing-2);
  color: var(--color-mint-20);
  font-size: 16px;
  font-weight: var(--font-weight-body);
  line-height: var(--line-height-body);
  font-family: var(--font-family);
  background-color: transparent;
}

.login-form {
  padding: 24px 0px;
}

.login-area .headings {
  text-align: center;
}

.terms-card {
  width: 800px;
  margin: 56px auto;
}

.arcault-h-stack.signin-logo {
  gap: 8px;
}

.login-form button {
  flex: 1;
}

.terms {
  align-items: center;
}

.card-header {
  color: var(--color-primary);
}

.arcault-button--accent {
  background-color: var(--color-accent);
  color: var(--color-neutral-1)
}

.arcault-button--accent:hover {
  background-color: var(--color-mint-50);
}

.arcault-button--accent:active {
  background-color: var(--color-mint-80);
}

.tabbed-area {
  flex: 1;
}

.inbox-full-card .card-body {
  display: flex;
  flex-direction: column;
}

.inbox-detail-header {
  flex: 0;
}

.tab-panel {
  display: flex;
  flex-direction: column;
  flex: 1
}

.table-controls .table-filters .form-input {
  padding-bottom: 0px;
}

/* to move */

.arcault-avatar { background-color: var(--color-accent) !important }

.table-data.arcault-h-stack, .active-filters.arcault-h-stack {
  align-items: center; flex: 0 0 32px;
}
