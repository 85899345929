.half-width-input {
    flex: 1 1 calc(50% - 16px);
}

.full-width-input {
    flex: 2 1 100%;
}

.form-stack {
    flex-wrap: wrap;
}