@import '../../../App.css';

.arcault-info-assets-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
    padding: var(--spacing-2);
    color: var(--color-primary);
}

.arcault-info-assets-table .arcault-table-head {
    border-bottom: 1px solid var(--color-neutral-10);
}

.arcault-info-assets-table  .arcault-table-body {
/*    min-height: 120px; */
}

.arcault-info-assets-table th {}

/* TODO move to storybook */
.button.button-sm {
    padding: var(--spacing-2);
    border-radius: var(--spacing-2);
    height: var(--spacing-6);
    font-size: var(--font-size-caption);
    font-weight: var(--font-weight-caption);
}