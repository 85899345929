@import '~@arcault-ui/shared-components/dist/styles.css';

.title {
  font-size: var(--font-size-h2);
  font-weight: var(--font-weight-bold);
}

.value {
  font-size: var(--font-size-h1);
  margin: var(--spacing-2) 0;
}

.value.has-value {
  color: var(--color-primary);
}

.value.no-value {
  color: var(--color-neutral-40);
}

.description {
    padding-top: var(--spacing-4);
    margin-bottom: var(--spacing-4);
}

.asset {
  color: var(--color-green);
}

.liability {
  color: var(--color-red);
}