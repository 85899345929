@import '../../../App.css';


.digital-vault .arcault-avatar {
    width: var(--spacing-6);
    height: var(--spacing-6);
    border: 1px solid white;
}

.digital-vault .arcault-avatar .chakra-avatar__initials {
    font-size: 10px;
    font-weight: 500;
    color: white;
}

.file-not-shared .icon {
    color: var(--color-neutral-10);
}

.digital-vault .dropzone {
    border: 1px solid #fff;
    border-bottom: 1px solid var(--color-neutral-10);
    background: linear-gradient(to bottom, white, var(--color-neutral-10));
    padding: var(--spacing-12) 0px 0px 0px;
    text-align: center;
    color: var(--color-secondary);
    height: 120px;
}

.search-bar {
    padding-bottom: 0px;
    padding-top: 16px;
}

.arcault-info-assets-table {
    margin-top: 12px;
}

.dropzone .body-text {
    font-size: var(--font-size-body);
    font-weight: var(--font-weight-body);
    color: var(--color-secondary-dark);
}

.dropzone.dropzone-active {
    background: linear-gradient(to bottom, var(--color-gold-1), var(--color-secondary-light));
    font-weight: bold;
    color: var(--color-secondary-dark);
}

.dropzone.dropzone-uploading, .dropzone.dropzone-uploading .body-text {
    color: var(--color-neutral-40); 
}

.dropzone.dropzone-uploading {
    background: linear-gradient(to bottom, white, var(--color-neutral-10));
    font-weight: bold;
    color: var(--color-neutral-40); 
}

.digital-vault .card-view {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
}

.digital-vault .view-switcher {
    height: 36px;
}


.doc-card {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-neutral-5);
}

.doc-icon .icon {
    font-size: 128px;
    color: var(--color-secondary);
}

.doc-card .card-body {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.doc-icon {
    text-align: center;
}

.doc-icon .caption {
    position: absolute;
    top: 100px;
    width: 176px;
    text-align: center;
    font-size: 24px;
}

.doc-label {
    width: 176px;
    display: flex;
    justify-content: center;
}

.doc-label .text {
    white-space: nowrap;       /* Prevent text from wrapping to the next line */
    overflow: hidden;          /* Hide the overflowed text */
    text-overflow: ellipsis; 
}

/* TODO - remove this */
.button-adjust {
    padding: 8px;
}